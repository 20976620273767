<template>
  <div class="caseinfo">
     <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="cotainer">
      <div class="header">
        <h1>污水处理厂项目</h1>
        <p>时间：2019-2.21</p>
      </div>
        <div class="introduce mt_30">
            <h2>应用项</h2>
            <p>1.BIM可视化项目管理平台</p>
            <p>2.整合众多单体及专业分包供应商</p>
            <p>3.制定项目流程、划分工作界面</p>
            <p>4.数据自动保存并移交运维</p>
            <h2>项目概况</h2>
            <p>占地约33.79公顷的原合流一期污水厂保留用地范围内新建的一座现代化的大型城市污水处理厂。</p>
        </div>
      <div class="pic"><img src="../../assets/image/cases/case_bj(5)_ws.png" alt=""></div>
      <div class="pic"><img src="../../assets/image/cases/case_bj(5)_ws2.jpg" alt=""></div>
    </div>
</div>
</template>

<script>
export default {
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>


<style lang="less" scoped>
.mt_30{
  margin-top: 30px;
}
.caseinfo{
  width: 100%;
  // height: 100%;
  .cotainer{
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    width: 100%;
    line-height: 30px;
    h1{
      font-weight: normal;
    }
    h2{
      line-height: 50px;
    }
    .header{
      p{
        color: rgba(51,51,51,1);
        opacity: 0.7;
      }
    }
  }
}
</style>
